import { Component, OnInit } from "@angular/core";
import { NavigationCancel, Router, RouterOutlet } from "@angular/router";
import { NgxSpinnerComponent } from "ngx-spinner";
import { filter } from "rxjs";
import { take } from "rxjs/operators";
import { RedirectService } from "./shared/services/redirect.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    standalone: true,
    imports: [RouterOutlet, NgxSpinnerComponent],
})
export class AppComponent implements OnInit {
    constructor(
        private router: Router,
        private redirectService: RedirectService,
    ) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((evt) => evt instanceof NavigationCancel),
                take(1),
            )
            .subscribe((event: NavigationCancel) => {
                this.redirectService.redirectUrl = event.url;
            });
    }
}
