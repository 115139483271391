import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDrawer, MatDrawerContent, MatSidenavModule } from "@angular/material/sidenav";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { SiteHeaderComponent } from "src/app/shared/components/site-header/site-header.component";
import { FooterComponent } from "../../components/footer/footer.component";
import { SiteNavComponent } from "../../components/site-nav/site-nav.component";

@Component({
    selector: "app-dashboard-layout",
    standalone: true,
    imports: [CommonModule, RouterModule, MatSidenavModule, SiteHeaderComponent, SiteNavComponent, FooterComponent],
    templateUrl: "./dashboard-layout.component.html",
    styleUrls: ["./dashboard-layout.component.scss"],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
    @ViewChild(MatDrawer) drawer: MatDrawer;
    @ViewChild(MatDrawerContent) drawerContent: MatDrawerContent;

    private destroy$ = new Subject<void>();

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.initNavigationSubscription();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onCloseDrawer(): void {
        this.drawer.close();
    }

    onToggleDrawer(): void {
        this.drawer.toggle();
    }

    private initNavigationSubscription() {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.drawerContent.scrollTo({ top: 0, behavior: "smooth" });
        });
    }
}
