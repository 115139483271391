import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";
import { enableProdMode, importProvidersFrom } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { bootstrapApplication } from "@angular/platform-browser";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { PreloadAllModules, provideRouter, withPreloading } from "@angular/router";
import { OwlNativeDateTimeModule } from "@danielmoncada/angular-datetime-picker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { provideEnvironmentNgxMask } from "ngx-mask";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";
import { AppComponent } from "./app/app.component";
import { APP_ROUTES } from "./app/app.routes";
import { HttpRequestInterceptor } from "./app/shared/services/http.service";
import { environment } from "./environments/environment";

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true,
        },
        provideEnvironmentNgxMask({
            validation: false,
        }),
        importProvidersFrom(
            BrowserAnimationsModule,
            MatSnackBarModule,
            HttpClientModule,
            TranslateModule.forRoot({
                defaultLanguage: "en",
                loader: {
                    provide: TranslateLoader,
                    useFactory: createTranslateLoader,
                    deps: [HttpClient],
                },
            }),
            NgxMatTimepickerModule.setLocale("en-GB"),
            OwlNativeDateTimeModule,
        ),
        provideRouter(APP_ROUTES, withPreloading(PreloadAllModules)),
        provideAnimations(),
    ],
}).catch((err) => console.log(err));
