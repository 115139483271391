import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { LocalStorageService } from "./local-storage.service";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: LocalStorageService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        let status = this.authService.isLoggedIn();
        if (!status) {
            this.router.navigate(["/authentication/login"]);
        }
        return status;
    }
}
