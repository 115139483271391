import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AuthHeroComponent } from "src/app/modules/authentication/_components/auth-hero/auth-hero.component";
import { SiteHeaderComponent } from "../../components/site-header/site-header.component";
import { FooterComponent } from "../../components/footer/footer.component";

@Component({
    selector: "app-auth-layout",
    standalone: true,
    imports: [CommonModule, RouterModule, AuthHeroComponent, SiteHeaderComponent, FooterComponent],
    templateUrl: "./auth-layout.component.html",
    styleUrls: ["./auth-layout.component.scss"],
})
export class AuthLayoutComponent {}
