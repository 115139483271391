<mat-drawer-container class="dashboard">
    <mat-drawer class="max-w-[300px]" [position]="'end'">
        <app-site-nav (linkClicked)="onCloseDrawer()"></app-site-nav>
    </mat-drawer>
    <mat-drawer-content>
        <app-header (toggleDrawer)="onToggleDrawer()"></app-header>
        <div class="dashboard__content">
            <div class="dashboard__content-wrapper">
                <router-outlet></router-outlet>
            </div>
        </div>
        <app-footer></app-footer>
    </mat-drawer-content>
</mat-drawer-container>
