<div class="href-link flex items-center" [matMenuTriggerFor]="language">
    <div class="icon">
        <mat-icon>language</mat-icon>
    </div>
    {{ selectedLanguage | uppercase | translate }}
</div>

<mat-menu #language="matMenu">
    <button mat-menu-item (click)="onSwitch('en')">
        <span>{{ "EN" | translate }}</span>
    </button>
    <button mat-menu-item (click)="onSwitch('bm')">
        <span>{{ "BM" | translate }}</span>
    </button>
</mat-menu>
