import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MaterialModule } from "../../material-module";

@Component({
    selector: "app-translator-dropdown",
    standalone: true,
    imports: [CommonModule, MaterialModule],
    templateUrl: "./translator-dropdown.component.html",
    styleUrls: ["./translator-dropdown.component.scss"],
})
export class TranslatorDropdownComponent implements OnInit {
    selectedLanguage = "";

    constructor(private translate: TranslateService) {}

    ngOnInit(): void {
        if (localStorage.getItem("locale")) {
            this.translate.use(localStorage.getItem("locale"));
            this.selectedLanguage = localStorage.getItem("locale");
        } else {
            const browserLang = this.translate.getBrowserLang();
            this.selectedLanguage = browserLang;
            this.translate.use(browserLang);
        }
    }

    onSwitch(lang: string): void {
        this.translate.use(lang);
        this.selectedLanguage = lang;
        localStorage.setItem("locale", lang);
    }
}
