import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class RedirectService {
    private _redirectUrl = "";

    get redirectUrl(): string {
        return this._redirectUrl;
    }

    set redirectUrl(url: string) {
        this._redirectUrl = url;
    }

    clearRedirectUrl(): void {
        this._redirectUrl = "";
    }
}
