<div class="footer-top">
    <div class="container">
        <div class="px-4">
            <img class="footer-top__logo" src="/assets/images/PS-Powered-By.svg" />
        </div>
        <div class="footer-top__content">
            <p class="mb-4">
                D-3A-06 & D-3A-07, Menara Suezcap 1, KL Gateway, No. 2 Jalan Kerinchi, Gerbang Kerinchi Lestari, 59200
                Kuala Lumpur
            </p>
            <p class="mb-2 flex">
                <img alt="" class="mr-2" src="/assets/icons/whatsapp-icon.svg" />
                +6018-2822320
            </p>
            <p class="mb-6 flex">
                <img alt="" class="mr-2" src="/assets/icons/email-icon.svg" />
                claim&#64;policystreet.com
            </p>
            <div
                class="flex items-center justify-between max-[576px]:flex-col max-[576px]:items-start max-[576px]:gap-4">
                <p class="fs-10 font-light" translate>
                    All rights reserved. PolicyStreet (Polisea Sdn Bhd (Registration No: 201601041144 (1212085-T) ))
                </p>
                <div class="fs-10 flex items-center gap-2 font-light">
                    Underwritten by
                    <img src="/assets/images/AIA-logo.svg" />
                </div>
            </div>
        </div>
    </div>
</div>

<div class="footer-bottom">
    <div class="container">
        <div class="pb-6">
            <p class="footer-bottom__heading" translate>Acceptance of Our Terms</p>
            <p translate>
                By visiting our Digital Claims portal, viewing, accessing or otherwise any of the services or
                information created, collected, compiled or submitted to digital claims portal, you agree to be bound by
                our
                <a (click)="onDownloadTNC()" class="footer-bottom__heading">Terms and Conditions of Service.</a>
            </p>
        </div>
        <div>
            <p class="footer-bottom__heading" translate>Provision of Services</p>
            <p translate>
                You agree and acknowledge that Policystreet is entitled to discontinue any of its services at its sole
                discretion and without notice to you even if it may result in you being prevented from accessing the
                portal. Furthermore, you agree and acknowledge this digital claim portal is built and maintained by
                Policystreet to process your claims related to Damage Protection Services only.
            </p>
        </div>
    </div>
</div>
