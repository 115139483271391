import { Injectable } from "@angular/core";
import { jwtDecode } from "jwt-decode";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    setAccessToken(token: string): void {
        localStorage.setItem("accessToken", token);
    }

    getAccessToken(): string {
        return localStorage.getItem("accessToken");
    }

    clearLocalStorage(): void {
        localStorage.clear();
    }

    isTokenExpired(): boolean {
        const token = this.getAccessToken();
        if (!token) return false;
        const decoded = jwtDecode(token);
        const expireTime = new Date(decoded["exp"] * 1000);

        return expireTime < new Date();
    }

    isLoggedIn(): boolean {
        if (!this.getAccessToken()) {
            return false;
        }

        if (!this.isTokenExpired()) {
            return true;
        }

        localStorage.removeItem("accessToken");
        return false;
    }
}
