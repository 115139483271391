<div class="pt-6">
    <mat-list role="list">
        <mat-list-item role="listitem">
            <div (click)="onClickTab('/policies')" [ngClass]="getRouteCssClass('policies')" class="href-div">
                <div class="list__icon">
                    <mat-icon>request_quote</mat-icon>
                </div>
                <div class="list__label" translate>My Policy</div>
            </div>
        </mat-list-item>

        <mat-list-item role="listitem">
            <div (click)="onClickTab('/my-account')" [ngClass]="getRouteCssClass('my-account')" class="href-div">
                <div class="list__icon">
                    <mat-icon>account_circle</mat-icon>
                </div>
                <div class="list__label" translate>My Account</div>
            </div>
        </mat-list-item>

        <mat-list-item role="listitem">
            <div (click)="onClickTab('/contact-us')" [ngClass]="getRouteCssClass('contact-us')" class="href-div">
                <div class="list__icon">
                    <mat-icon>perm_phone_msg</mat-icon>
                </div>
                <div class="list__label" translate>Contact Us</div>
            </div>
        </mat-list-item>

        <mat-list-item class="mt-12" role="listitem">
            <div (click)="onLogout()" class="href-div">
                <div class="list__icon">
                    <mat-icon>logout</mat-icon>
                </div>
                <div class="list__label" translate>Log out</div>
            </div>
        </mat-list-item>
    </mat-list>
</div>
