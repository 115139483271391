import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "../../material-module";

@Component({
    selector: "app-footer",
    standalone: true,
    imports: [CommonModule, RouterModule, MaterialModule],
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
    onDownloadTNC(): void {
        let language = localStorage.getItem("locale");
        let path = language == "en" ? "en" : "bm";
        window.open(`https://storage.googleapis.com/ps-asset/shopee/policystreet_shopee_tnc_${path}.pdf`, "_blank");
    }
}
