import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Router, RouterModule } from "@angular/router";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";
import { TranslatorDropdownComponent } from "../translator-dropdown/translator-dropdown.component";

@Component({
    selector: "app-header",
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatSelectModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatToolbarModule,
        TranslatorDropdownComponent,
        RouterModule,
    ],
    templateUrl: "./site-header.component.html",
    styleUrls: ["./site-header.component.scss"],
})
export class SiteHeaderComponent {
    @Output() toggleDrawer = new EventEmitter<void>();

    isLoggedIn = this.localStorageService.isLoggedIn();

    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
    ) {}

    getRouteCssClass(route: string): string {
        return this.router.url.includes(route) ? "link-focus" : "";
    }

    onLogout(): void {
        this.localStorageService.clearLocalStorage();
        this.router.navigateByUrl("authentication");
    }

    onToggleDrawer(): void {
        this.toggleDrawer.emit();
    }
}
