import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatSidenavModule } from "@angular/material/sidenav";
import { Router, RouterModule } from "@angular/router";
import { LocalStorageService } from "../../services/local-storage.service";

@Component({
    selector: "app-site-nav",
    standalone: true,
    imports: [CommonModule, RouterModule, MatSidenavModule, MatIconModule, MatListModule, MatExpansionModule],
    templateUrl: "./site-nav.component.html",
    styleUrls: ["./site-nav.component.scss"],
})
export class SiteNavComponent {
    @Output() linkClicked = new EventEmitter<void>();

    constructor(
        private router: Router,
        private localStorageService: LocalStorageService,
    ) {}

    onClickTab(to: string): void {
        this.router.navigateByUrl(to);
        this.linkClicked.emit();
    }

    getRouteCssClass(route: string): string {
        return this.router.url.includes(route) ? "link-focus" : "";
    }

    onLogout(): void {
        this.localStorageService.clearLocalStorage();
        this.router.navigateByUrl("authentication/login");
    }
}
