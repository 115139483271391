import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";

@Component({
    selector: "app-auth-hero",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./auth-hero.component.html",
    styleUrls: ["./auth-hero.component.scss"],
})
export class AuthHeroComponent {}
