import { Routes } from "@angular/router";
import { AuthLayoutComponent } from "./shared/layouts/auth-layout/auth-layout.component";
import { DashboardLayoutComponent } from "./shared/layouts/dashboard-layout/dashboard-layout.component";
import { AuthGuard } from "./shared/services/guard.service";

export const APP_ROUTES: Routes = [
    {
        path: "authentication",
        component: AuthLayoutComponent,
        loadChildren: () => import("./modules/authentication/auth.routes").then((m) => m.AUTH_ROUTES),
    },
    {
        path: "",
        canActivate: [AuthGuard],
        component: DashboardLayoutComponent,
        children: [
            {
                path: "",
                redirectTo: "policies",
                pathMatch: "full",
            },
            {
                path: "policies",
                loadChildren: () => import("./modules/policies/policies.routes").then((r) => r.POLICIES_ROUTES),
            },
            {
                path: "contact-us",
                loadChildren: () => import("./modules/contact-us/contact-us.routes").then((r) => r.CONTACT_US_ROUTES),
            },
            {
                path: "my-account",
                loadChildren: () => import("./modules/my-account/my-account.routes").then((r) => r.MY_ACCOUNT_ROUTES),
            },
        ],
    },
    {
        path: "**",
        redirectTo: "authentication/login",
    },
];
