<app-header></app-header>

<div class="split container">
    <div class="split__left">
        <app-auth-hero class="w-full"></app-auth-hero>
    </div>
    <div class="split__right">
        <div class="text-center">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-footer></app-footer>
