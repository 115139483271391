<div class="header header--desktop">
    <div class="container flex justify-between">
        <div class="header__left">
            <div [routerLink]="isLoggedIn ? '/policies' : '/authentication/login'" class="flex cursor-pointer">
                <img class="header__logo" src="/assets/images/ps-logo.png" />
            </div>
        </div>
        <div class="header__right">
            <div *ngIf="isLoggedIn" class="href-link" routerLink="/policies" [ngClass]="getRouteCssClass('policies')">
                <div class="header__icon">
                    <mat-icon>request_quote</mat-icon>
                </div>
                <div class="header__label" translate>My Policy</div>
            </div>
            <div
                *ngIf="isLoggedIn"
                class="href-link"
                routerLink="/my-account"
                [ngClass]="getRouteCssClass('my-account')">
                <div class="header__icon">
                    <mat-icon>account_circle</mat-icon>
                </div>
                <div class="header__label" translate>My Account</div>
            </div>
            <div
                routerLink="/contact-us"
                *ngIf="isLoggedIn"
                [ngClass]="getRouteCssClass('contact-us')"
                class="href-link">
                <div class="header__icon">
                    <mat-icon>perm_phone_msg</mat-icon>
                </div>
                <div class="header__label" translate>Contact Us</div>
            </div>
            <!-- 25/11/2024: Confirmed by Winter, only support english language -->
            <!-- https://www.figma.com/design/S8QrkRotKuhpmWwDCnC4DG?node-id=108-15111#1034919080 -->
            <!-- <app-translator-dropdown></app-translator-dropdown> -->
            <div (click)="onLogout()" *ngIf="isLoggedIn" class="href-link">
                <div class="header__icon">
                    <mat-icon>logout</mat-icon>
                </div>
                <div class="header__label" translate>Log out</div>
            </div>
        </div>
    </div>
</div>

<div class="header header--mobile">
    <div class="container flex justify-between">
        <div class="header__left">
            <div class="flex cursor-pointer" [routerLink]="isLoggedIn ? '/policies' : '/authentication/login'">
                <img class="header__logo" src="/assets/images/ps-logo.png" />
            </div>
        </div>
        <div class="header__right">
            <app-translator-dropdown></app-translator-dropdown>
            <div *ngIf="isLoggedIn" class="href-link">
                <div class="header__icon" (click)="onToggleDrawer()">
                    <mat-icon>menu</mat-icon>
                </div>
            </div>
        </div>
    </div>
</div>
